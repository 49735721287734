<template>
    <div style="padding: 10px 20px">
        <el-form ref="form" status-icon label-width="90px" label-position="left">
            <el-form-item prop="phone" label="增加章节：">
                <el-input v-model="title" maxlength="20" placeholder="请输入章节标题" show-word-limit type="text" />
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="addChapter()" style="width: 100%">保存</el-button>
    </div>
</template>

<script>
import { apiAddChapter } from "@/api/admin/chapter.js";

export default {
    //声明数据源
    data() {
        return {
            title: ""
        };
    },
    methods: {
        async addChapter() {
            let formData = new FormData();
            //去除首尾空格
            this.title = this.title.trim();
            if (this.title == "") {
                this.$message({ message: "请输入标题", type: "error" });
                return;
            }
            formData.append("token", this.$store.state.token);
            formData.append("topicId", this.$route.query.topicId);
            formData.append("title", this.title);

            //调用接口
            const response = await apiAddChapter(formData);
            if (response.data.code === 200) {
                //调用父页面关闭layer
                window.parent.postMessage({ message: "增加课题章节成功", code: "200" }, "*");
            } else {
                this.$message({ message: response.data.message, type: "error" });
            }
        }
    }
};
</script>
